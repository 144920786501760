const documentField = [
    {
        key: "title",
        label: "제목",
        tdClass: "docTitleClass"
    },
    {
        key: "content",
        label: "내용",
        tdClass: "threeLine"
    },
    {
        key: "nick_name",
        label: "닉네임"
    },
    {
        key: "comment_count",
        label: "답변상태"
    }
]

export {documentField}
