<script>
import _ from "lodash";
import axios from "axios";
import appConfig from "@/app.config.json";
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import {postActions, postMutations, postStore} from "@/state/modules/postStoreHelper";
import {accountMutations, accountStore} from "@/state/modules/accountStoreHelper";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";
import {documentField} from "@/views/pages/Q&A/Q&AIndex/documentField";
import PostHeaderLeft from "@/views/pages/Q&A/Q&AIndex/header/documentHeaderLeft.vue";
import PostHeaderRight from "@/views/pages/Q&A/Q&AIndex/header/documentHeaderRight.vue";
import {loggedInfoObject} from "@/state/helpers";
import {documentAdminField} from "@/views/pages/Q&A/Q&AIndex/documentAdminField";

export default {
  page: {
    title: "Q&A",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  data() {
    return {
      title: "Q&A",
      // postList: [],
      selectedList: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      srchOptions: [
        {text: '제목', value: 'title'},
        {text: '글쓴이', value: 'nick_name'}
      ],
      srchSelected: "title",
      allSelected: false,
      fields: documentField,
      adminFields: documentAdminField,
      btnAble: true,
      totalCnt: 0,
      categoryNum: 0,
      srchKey: "",
      isTalkOnly: false,
      isTalkOnlys: false,
      radioBtnValue: "N",
      docsSdate: null,
      docsEdate: null,
      optionSdate: null,
      optionEdate: null,
      datepickerLang: {
        yearFormat: "YYYY년",
        monthFormat: "M월",
        monthBeforeYear: false,
      },
      showTimePanel: false,
      showTimeRangePanel: false,
      isAnalytics: false,
      // analyticsField: documentAnalyticsField,
    };
  },

  components: {
    PostHeaderRight,
    PostHeaderLeft,
    Layout,
    PageHeader,
  },

  computed: {
    ...postStore,
    postList() {
      return _.cloneDeep(this.postVuexList)
    },
    postAnalyticsInfoList() {
      return _.cloneDeep(this.postVuexAnalyticsInfo)
    },
    ...accountStore,
    ...loggedInfoObject
  },

  async mounted() {
    await this.setResetPostList()

    let postPayloadData = {}

    if (this.logged_info.level === 10) {
      await this.setDateCondition()

      postPayloadData = {
        page: this.currentPage,
        callCnt: this.perPage,
        condition: {}
      }
    } else {
      postPayloadData = {
        page: this.currentPage,
        callCnt: this.perPage,
        condition: {
          market_srl: Number(this.logged_info.mart_srl)
        }
      }
    }

    await this.returnPostPeriodCondition(postPayloadData)
    await this.getPostingList({
          payload: postPayloadData
        }
    )

    // this.setVoteList([{
    //   allow_trackback: "Y",
    //   blamed_count: 0,
    //   cat_thumbnail_path: "uploads/talkCategory/20240425_140440/freetalk.png",
    //   category_srl: 46,
    //   category_title: "자유Talk",
    //   comment_count: 1,
    //   comment_status: "ALLOW",
    //   content: "<p>이건 뭐 우수수수 너무 낙폭이 큰것 같습니다. </p><p>오늘은 좀 오르....겠죠???</p><p><br></p>",
    //   document_srl: 488263,
    //   edate: "",
    //   email_address: "",
    //   extra_vars: "",
    //   homepage: "",
    //   ipaddress: "",
    //   is_bookmark: "N",
    //   is_notice: "N",
    //   is_survey: "N",
    //   is_voted: 0,
    //   lang_code: "",
    //   last_update: "2024-08-06 11:32:17",
    //   last_updater: "",
    //   list_order: 0,
    //   member_srl: 66245,
    //   member_thumbnail_path: "",
    //   module_srl: 45,
    //   nick_name: "조랭이떡",
    //   notify_message: "N",
    //   password: "",
    //   phone: "01093210088",
    //   readed_count: 2,
    //   regdate: "2024-08-06 11:32:17",
    //   same_job: "",
    //   sdate: "",
    //   selected: false,
    //   status: "PUBLIC",
    //   survey_cnt: 0,
    //   tags: "",
    //   thumbnail_path: "",
    //   title: "주식 너무 떨어졌네요. ",
    //   title_bold: "N",
    //   title_color: "",
    //   trackback_count: 0,
    //   update_order: 0,
    //   uploaded_count: 0,
    //   uploaded_filename: "",
    //   user_id: "haewonbsb1",
    //   user_name: "전혜원",
    //   voted_count: 0
    // }])
  },

  methods: {
    ...postActions,
    ...postMutations,
    ...accountMutations,

    async setDateCondition() {
      const today = new Date(); // 현재 날짜와 시간을 가져옴
      this.docsSdate = new Date(today.getFullYear(), today.getMonth(), 1)
      this.docsEdate = today
      let smonth = this.docsSdate.getMonth() + 1
      if (smonth < 10) {
        smonth = '0' + smonth
      }
      let emonth = this.docsEdate.getMonth() + 1
      if (emonth < 10) {
        emonth = '0' + emonth
      }
      this.optionSdate = this.docsSdate.getFullYear() + "-" + smonth + "-" + this.docsSdate.getDate()
      this.optionEdate = this.docsEdate.getFullYear() + "-" + emonth + "-" + this.docsEdate.getDate()
    },

    allSelectAction() {
      if (this.postList != null) {
        this.selectedList = [];

        this.postList.forEach((row) => {
          row.selected = this.allSelected;

          if (row.selected) {
            this.selectedList.push(row);
          }
        });
      }

      this.selectedList.length > 0 ? this.btnAble = false : this.btnAble = true
    },

    selectedCheck() {
      this.selectedList = []
      this.postList.forEach((row) => {
        if (row.selected) {
          this.selectedList.push(row)
        }
      })

      this.selectedList.length > 0 ? this.btnAble = false : this.btnAble = true
      this.selectedList.length === this.postList.length ? this.allSelected = true : this.allSelected = false
    },

    async makeListWithPeriodData() {
      console.log("this.categoryNum : ", this.categoryNum)
      this.currentPage = 1
      this.selectedList = []

      let payloadPostData = {
        page: this.currentPage,
        callCnt: this.perPage,
        condition: {}
      }
      if (!this.isAnalytics) {
        await this.makeReturnPostCondition(payloadPostData)
      }

      await this.returnPostPeriodCondition(payloadPostData)
      console.log(payloadPostData)
      if (!this.isAnalytics) {
        await this.getPostingList({
          payload: payloadPostData
        })
      } else {
        await this.getAnalyticsInfo({
          payload: payloadPostData
        })
      }
    },

    async getAnalyticsInfoList() {
      this.currentPage = 1
      this.perPage = 10
      this.srchKey = ""
      this.selectedList = []
      this.btnAble = true
      let payloadPostData = {
        page: this.currentPage,
        callCnt: this.perPage,
        condition: {}
      }
      await this.returnPostPeriodCondition(payloadPostData)

      await this.getAnalyticsInfo({
        payload: payloadPostData
      })

    },
    async changePageForAnalyticsInfo() {
      this.selectedList = []
      this.btnAble = true
      this.allSelected = false
      this.srchKey = ""

      let payloadPostData = {
        page: this.currentPage,
        callCnt: this.perPage,
        condition: {}
      }

      await this.returnPostPeriodCondition(payloadPostData)

      await this.getAnalyticsInfo({
        payload: payloadPostData
      })

      await window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    },

    async changePostCategory(val) {
      if (val < 5) {
        this.isAnalytics = false
        this.categoryNum = val
        this.currentPage = 1
        this.perPage = 10
        this.srchKey = ""
        this.selectedList = []
        this.btnAble = true

        let payloadPostData = {
          page: this.currentPage,
          callCnt: this.perPage,
          condition: {}
        }

        await this.makeReturnPostCondition(payloadPostData)

        await this.returnPostPeriodCondition(payloadPostData)

        await this.getPostingList({
          payload: payloadPostData
        })
      } else if (val == 5) {
        this.isAnalytics = true
        this.getAnalyticsInfoList()
      }
    },

    async changePage() {
      this.selectedList = []
      this.btnAble = true
      this.allSelected = false

      let payloadPostData = {
        page: this.currentPage,
        callCnt: this.perPage,
        condition: {}
      }

      if (this.srchKey == "") {
        await this.makeReturnPostCondition(payloadPostData)
      } else {
        payloadPostData.condition[this.srchSelected] = this.srchKey
      }
      await this.returnPostPeriodCondition(payloadPostData)

      await this.getPostingList({
        payload: payloadPostData
      })

      await window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    },

    // 검색
    async srchPostByKeyword() {
      this.categoryNum = 0

      let payloadPostData = {
        page: this.currentPage,
        callCnt: this.perPage,
        condition: {}
      }

      payloadPostData.condition[this.srchSelected] = this.srchKey

      await this.returnPostPeriodCondition(payloadPostData)

      await this.getPostingList({
        payload: payloadPostData
      })
      await window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    },

    // 검색 취소
    async cancelPostSrchAction() {
      this.srchKey = ""

      if (this.categoryNum == 0) {
        let payloadPostData = {
          page: this.currentPage,
          callCnt: this.perPage,
          condition: {}
        }

        await this.returnPostPeriodCondition(payloadPostData)

        this.getPostingList({
          payload: payloadPostData
        })
      }
    },

    makeReturnPostCondition(val) {
      if (this.categoryNum == 1) {
        return val.condition['status'] = 'PUBLIC'
      } else if (this.categoryNum == 2) {
        return val.condition['status'] = "SECRET"
      } else if (this.categoryNum == 4) {
        return val.condition["blamed_count"] = 1
      }
    },
    returnPostPeriodCondition(val) {
      if (this.isTalkOnly) {
        val.condition['module_srl'] = 45
      } else {
        delete val.condition['module_srl']
      }
      if (this.docsSdate == null && this.docsEdate == null) {
        delete val.condition['sdate']
        delete val.condition['edate']
      } else if (this.docsSdate == null && this.docsEdate != null) {
        this.$bvModal.msgBoxOk("조회 시작일을 지정해주세요.", msgModalOptCenter)
        console.log("this.docsSdate", this.optionSdate)
      } else if (this.docsSdate != null && this.docsEdate == null) {
        this.$bvModal.msgBoxOk("조회 종료일을 지정해주세요.", msgModalOptCenter)
        console.log("this.docsEdate", this.optionEdate)
      } else {
        val.condition['sdate'] = this.optionSdate
        val.condition['edate'] = this.optionEdate
      }
    },

    async deleteSelectedPost() {
      await this.$bvModal.msgBoxConfirm(`선택한 게시물을 삭제하시겠습니까? `, msgConfirmOptCenter)
          .then(async (sel) => {
            if (sel) {
              console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%", this.selectedList)

              for (let i = this.selectedList.length - 1; i >= 0; i--) {
                await this.deletePost(this.selectedList[i].document_srl)
              }

              let payloadPostData = {
                page: this.currentPage,
                callCnt: this.perPage,
                condition: {}
              }

              if (this.srchKey !== '') {
                payloadPostData.condition[this.srchSelected] = this.srchKey
              }

              await this.makeReturnPostCondition(payloadPostData)

              await this.returnPostPeriodCondition(payloadPostData)

              await this.getPostingList({
                payload: payloadPostData
              })

              this.selectedList = []
              this.btnAble = true
              this.allSelected = false
            }
          })
    },

    async deletePost(val) {
      let url = `/api/post/delete/${val}`
      console.log(url)

      await axios.delete(
          url,
          {
            headers: {
              accessToken: this.accountVuexInfo.accessToken,
              refreshToken: this.accountVuexInfo.refreshToken
            },
          })
          .then((res) => {
            console.log(res.data)
          })
          .catch((err) => {
                console.log("deletePost Error :", err)
                if (!_.isEmpty(err.response)) {
                  if (err.response.status === 401) {
                    this.setCheckAuth(false)
                  }
                }
              }
          )
    },

    changeSelectedPost(val) {
      let msg = ""

      if (val) {
        msg = "선택한 게시물을 공개로 전환하시겠습니까?"
      } else {
        msg = "선택한 게시물을 비공개로 전환하시겠습니까?"
      }

      this.$bvModal.msgBoxConfirm(msg, msgConfirmOptCenter)
          .then(async (sel) => {
            if (sel) {
              for (let i = this.selectedList.length - 1; i >= 0; i--) {
                await this.changePostStatus(this.selectedList[i].document_srl, val)
              }

              let payloadPostData = {
                page: this.currentPage,
                callCnt: this.perPage,
                condition: {}
              }

              if (this.srchKey !== '') {
                payloadPostData.condition[this.srchSelected] = this.srchKey
              }

              await this.makeReturnPostCondition(payloadPostData)

              await this.returnPostPeriodCondition(payloadPostData)

              await this.getPostingList({
                payload: payloadPostData
              })

              this.selectedList = []
              this.btnAble = true
              this.allSelected = false
            }
          })
    },

    changePostStatus(dSrl, status) {
      let url = `/api/post/update`

      let changeData = {
        document_srl: dSrl,
        status: status ? 'PUBLIC' : 'SECRET'
      }

      axios.put(
          url,
          changeData,
          {
            headers: {
              accessToken: this.accountVuexInfo.accessToken,
              refreshToken: this.accountVuexInfo.refreshToken
            },
          })
          .then((res) => {
            console.log(res.data)
          })
          .catch((err) => {
            console.log("changePostStatus Error :", err)
            if (!_.isEmpty(err.response)) {
              if (err.response.status === 401) {
                this.setCheckAuth(false)
              }
            }
          })
    },

    removeContentTag(val) {
      return val.replaceAll(/<[^>]*>?/g, ' ')
    },

    handleOnClosing(val) {
      //console.log("this.docsEdate", this.docsEdate.toUTCString())
      let tmpDate = ''
      if (val == 's') {
        tmpDate = this.docsSdate
      } else {
        tmpDate = this.docsEdate
      }

      const year = tmpDate.getFullYear()
      let month = tmpDate.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      const date = tmpDate.getDate()
      if (val == 's') {
        this.optionSdate = year + "-" + month + "-" + date
        console.log("this.docsSdate", this.optionSdate)
      } else {
        this.optionEdate = year + "-" + month + "-" + date
        console.log("this.docsEdate", this.optionEdate)
      }
      //this.optionEdate = year + "-" + month + "-" + date
      //console.log("this.docsEdate", this.optionEdate)

    },

    async excelDownload() {
      let payloadPostData = {
        page: this.currentPage,
        callCnt: this.perPage,
        condition: {}
      }

      await this.makeReturnPostCondition(payloadPostData)

      await this.returnPostPeriodCondition(payloadPostData)
      console.log(payloadPostData)
      await this.callForExcelDown({
        payload: payloadPostData
      })
    },
    async excelDownloadForAnalytics() {
      let payloadPostData = {
        page: this.currentPage,
        callCnt: this.perPage,
        condition: {}
      }

      await this.makeReturnPostCondition(payloadPostData)

      await this.returnPostPeriodCondition(payloadPostData)
      console.log(payloadPostData)
      await this.callForExcelDownForAnalytics({
        payload: payloadPostData
      })
    },
    radioBtnStatus() {
      this.radioBtnValue = this.radioBtnValue === "N" ? "Y" : "N"
      this.isTalkOnly = !this.isTalkOnly ? true : false
      console.log("this.radioBtnValue : ", this.radioBtnValue)
      console.log("this.isTalkOnly : ", this.isTalkOnly)
    },

    moveToQADetail(val) {
      console.log("moveToQADetail", val)
      this.setPostDetail(val)
      this.$router.push('/board/qa/detail')
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="card">
      <div v-if="logged_info.level === 10" class="card-header d-flex justify-content-between align-items-center">
        <post-header-left
            v-if="false"
            :total-cnt="postTotalCnt"
            :cat-num="categoryNum"
            @changeCategory="changePostCategory"
        />
        <div style="width: 60px"/>
        <post-header-right
            :btn-able="btnAble"
            @deletePostList="deleteSelectedPost"
            @changeStatusPrivate="changeSelectedPost"
            @changeStatusPublic="changeSelectedPost"
        />
      </div>

      <div v-else class="card-header d-flex justify-content-end align-items-center">
        <b-button variant="primary" size="sm" @click="$router.push({path:'/board/qa/write', query: {'status': 'new'}})">
          Q&A 등록
        </b-button>
      </div>

      <div class="card-body">
        <div class="row" v-if="logged_info.level === 10">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Q&A&nbsp;
                <b-form-select
                    class="form-select form-select-sm"
                    style="width: auto"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    @change="changePage"
                ></b-form-select
                >&nbsp;개씩 보기
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_filter" class="dataTables_filter text-md-end d-flex">
              <b-form-select
                  class="form-select form-select-sm"
                  style="margin-right: 10px; width: auto"
                  v-model="srchSelected"
                  size="sm"
                  :options="srchOptions"
              ></b-form-select>
              <b-input-group size="sm">
                <b-form-input v-model="srchKey" placeholder="찾기..." @keypress.enter="srchPostByKeyword"></b-form-input>
                <b-button size="sm" variant="primary" @click="srchPostByKeyword">검색</b-button>
                <b-button size="sm" variant="secondary" @click="cancelPostSrchAction">취소</b-button>
              </b-input-group>
            </div>
          </div>
          <!-- End search -->
        </div>

        <div class="row justify-content-end mb-3">
          <div v-if="logged_info.level === 10" class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end"
               style="flex-direction: row">

            <div>조회 기간</div>

            <div class="col-md-3 d-flex h-100">
              <date-picker
                  v-model="docsSdate"
                  format="YYYY-MM-DD"
                  type="datetime"
                  placeholder="시작일 설정"
                  :editable="false"
                  :lang="datepickerLang"
                  :show-time-panel="false"
                  @close="handleOnClosing('s')"
                  class="datePickerClass form-select-sm"
              >
              </date-picker>

            </div>
            <div class="ms-2 " style="align-content: center"> ~</div>
            <div class="col-md-3 d-flex h-100">
              <date-picker
                  v-model="docsEdate"
                  format="YYYY-MM-DD"
                  type="datetime"
                  placeholder="종료일 설정"
                  :editable="false"
                  :lang="datepickerLang"
                  :show-time-panel="false"
                  @close="handleOnClosing('e')"
                  class="datePickerClass form-select-sm"
              >
              </date-picker>
            </div>
            <div class="d-flex h-100 form-select-sm">
              <b-button variant="primary" size="sm" @click="makeListWithPeriodData">조회</b-button>
            </div>
          </div>
          <!-- End search -->
        </div>
        <!-- Table -->
        <div v-if="!this.isAnalytics">
          <div class="table-responsive mb-0">
            <b-table
                hover
                :items="postList"
                :fields="logged_info.level === 10 ? adminFields : fields"
                responsive="sm"
                :current-page="currentPage"
                thead-class="thBoardCustom"
                @row-clicked="(item) => moveToQADetail(item)"
            >
              <template #head()="row">
                {{ row.label }}
                <b-form-group v-if="row.label === ''">
                  <b-form-checkbox v-model="allSelected" @change="allSelectAction" variant="outline-secondary"
                                   class="mr-1">
                  </b-form-checkbox>
                </b-form-group>
              </template>

              <template #cell(content)="row">
                {{ removeContentTag(row.item.content) }}
              </template>

              <template #cell(comment_count)="row">
                <div style="min-width: 52px;" :class="row.item.comment_count === 0 ? 'readyColor' : 'endColor'">
                  {{ row.item.comment_count === 0 ? '답변대기' : '답변완료' }}
                </div>
              </template>

              <template #cell(status)="row">
                {{ row.item.status === 'PUBLIC' ? '공개' : "비공개" }}
              </template>

              <template #cell(selected)="row">
                <b-form-group>
                  <b-form-checkbox v-model="row.item.selected" @change="selectedCheck" variant="outline-secondary"
                                   class="mr-1">
                  </b-form-checkbox>
                </b-form-group>
              </template>
            </b-table>
          </div>

          <div class="row justify-content-end">
            <div v-if="false" class="col-sm-12 col-md-6">
              <b-button variant="outline-success" size="sm" @click="excelDownload">엑셀 다운로드</b-button>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="postTotalCnt"
                      :per-page="perPage"
                      @input="changePage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.thBoardCustom > tr > th {
  min-width: 64px;
}

.docTitleClass {
  min-width: 200px;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.threeLine {
  min-width: 360px;
  max-width: 360px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.datePickerClass {
  width: 100%;
  height: 100%;

  .mx-input-wrapper {
    height: 100%;

    .mx-input {
      border: 1px solid #e2e5e8;
      line-height: 1.5;

      font-size: inherit;
      height: 100%;
    }
  }
}

.readyColor {
  color: red;
}

.endColor {
  color: #038edc;
}
</style>
