<script>
export default {
  name: "PostHeaderLeft",
  props: ['totalCnt', 'catNum'],
  methods: {
    changeCategory(e) {
      console.log("event", e.target.value)
      // this.categoryNum = e.target.value
      this.$emit('changeCategory', e.target.value)
    }
  }
}
</script>

<template>
  <div class="btn-group mt-2" role="group">
    <input
        type="radio"
        class="btn-check"
        name="btnradio"
        id="btnradio1"
        autocomplete="off"
        checked
        value="0"
        v-model="catNum"
        @change="changeCategory($event)"
    />
    <label class="btn btn-outline-primary" for="btnradio1">
      전체
      {{ catNum == 0 ? "(" + totalCnt + ")" : "" }}
    </label>

    <input
        type="radio"
        class="btn-check"
        name="btnradio"
        id="btnradio2"
        autocomplete="off"
        value="1"
        @change="changeCategory($event)"
    />
    <label class="btn btn-outline-primary" for="btnradio2">
      공개
      {{ catNum == 1 ? "(" + totalCnt + ")" : "" }}
    </label>

    <input
        type="radio"
        class="btn-check"
        name="btnradio"
        id="btnradio3"
        autocomplete="off"
        value="2"
        @change="changeCategory($event)"
    />
    <label class="btn btn-outline-primary" for="btnradio3">
      비밀
      {{ catNum == 2 ? "(" + totalCnt + ")" : "" }}
    </label>

    <input
        v-if="false"
        type="radio"
        class="btn-check"
        name="btnradio"
        id="btnradio4"
        autocomplete="off"
        value="3"
        @change="changeCategory($event)"
    />
    <label v-if="false" class="btn btn-outline-primary" for="btnradio4">
      임시
      {{ catNum == 3 ? "(" + totalCnt + ")" : "" }}
    </label>

    <input
        v-if="false"
        type="radio"
        class="btn-check"
        name="btnradio"
        id="btnradio5"
        autocomplete="off"
        value="4"
        @change="changeCategory($event)"
    />
    <label v-if="false" class="btn btn-outline-primary" for="btnradio5">
      신고 목록
      {{ catNum == 4 ? "(" + totalCnt + ")" : "" }}
    </label>

    <input
        v-if="false"
        type="radio"
        class="btn-check"
        name="btnradio"
        id="btnradio6"
        autocomplete="off"
        value="5"
        @change="changeCategory($event)"
    />
    <label v-if="false" class="btn btn-outline-primary" for="btnradio6">
      통계
    </label>
  </div>
</template>

<style scoped>

</style>
