<script>
export default {
  name: "PostHeaderRight",
  props: ['btnAble']
}
</script>

<template>
  <b-button-toolbar>
    <b-button-group class="mx-1">
      <b-button v-if="false" class="btn btn-soft-primary" :disabled="btnAble">휴지통</b-button>
      <b-button class="btn btn-soft-primary" size="sm" :disabled="btnAble" @click="$emit('deletePostList')">삭제</b-button>
      <b-button v-if="false" class="btn btn-soft-primary" :disabled="btnAble" @click="$emit('changeStatusPrivate', false)">비공개 전환</b-button>
      <b-button v-if="false" class="btn btn-soft-primary" :disabled="btnAble" @click="$emit('changeStatusPublic', true)">공개 전환</b-button>
    </b-button-group>
  </b-button-toolbar>
</template>

<style scoped>

</style>
